<template>
    <div>
        <multiselect
            v-model="selected"
            :options="options"
            label="text"
            track-by="value"
            :placeholder="$t('select')"
            :multiple="multiple"
            :close-on-select="!multiple"
            :clear-on-select="!multiple"
            :select-label="''"
            :selected-label="''"
            :deselect-label="''"
            @input="handleInput($event, multiple)"
        >
            <template slot="selection" slot-scope="{values, search, isOpen}">
                <span class="multiselect__single" v-if="values.length && !isOpen">
	                {{ translateNSelected(values) }}
                </span>
            </template>
            <span slot="noOptions">{{ $t("no_options") }}</span>
            <span slot="noResult">{{ $t("no_result") }}</span>
        </multiselect>
        <span class="invalid-feedback-custom"
              v-show="validateError"
              v-html="validateError"/>
    </div>
</template>

<script>
// Helpers
import translateNSelected from "@/helpers/translateNSelected";
import handleInput from "@/helpers/handleInput";
import setSelected from "@/helpers/setSelected";

// Services
import YearService from "@/services/YearService";

export default {
    props: {
        value: {
            default: null
        },
        validateError: {
            type: String,
            default: ''
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        onlyActive: {
            type: Boolean,
            default: false,
        },
        untilActive: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            selected: null,
            options: [],
        }
    },
    watch: {
        value: function (newValue) {
            this.selected = this.setSelected(newValue, this.options, this.multiple)
        }
    },
    created() {
        this.getOptions();
    },
    methods: {
        translateNSelected: translateNSelected,
        handleInput: handleInput,
        setSelected: setSelected,
        getOptions() {
            const key = 'finance_years';
            let local = this.getWithExpiry(key);
            if (local) {
                this.setOptions(local);
                this.selected = this.setSelected(this.value, this.options, this.multiple)
                return;
            }
            this.options = [];
            let params = {};
            if (this.onlyActive) {
                params.only_active = true;
            }
            if (this.untilActive) {
                params.until_active = true;
            }

            YearService.options({params: params})
                .then(response => {
                    const data = response.data.data;
                    this.setWithExpiry(key, data);
                    this.setOptions(data);
                })
                .then(() => {
                    this.selected = this.setSelected(this.value, this.options, this.multiple)
                })
        },
        setOptions(data){
            data.forEach((item) => {
                this.options.push({
                    value: item.id,
                    text: item.academic_year,
                });
            });
        },
        getItem(id){
            let found=null
            this.options.forEach((item)=>{
                if(item.value===id){
                    found = item;
                }
            });
            return found;
        }
    }
}
</script>
