import Api from '@/services/Index';

const getAll = async (config) => {
    return Api.get('/uploaded/student/documents', config);
}

const student = async (config) => {
    return Api.get('/uploaded/student/documents/student', config);
}
const get = async (code) => {
    return Api.get('/uploaded/student/documents/' + code);
}

const store = async (data) => {
    if (data.id) {
        return Api.put('/uploaded/student/documents/' + data.id, data);
    }
    return Api.post('/uploaded/student/documents', data);
}

const update = async (id, formData) => {
    return Api.put('/uploaded/student/documents/' + id, formData);
}

const del = async (id) => {
    return Api.delete('/uploaded/student/documents/' + id);
}

const download = async (id) => {
    return Api.get('/uploaded/student/documents/' + id + '/download', {responseType: 'arraybuffer'});
}

export default {
    getAll,
    get,
    store,
    update,
    del,
    download,
    student
}
